<!--
 * @Author: your name
 * @Date: 2021-12-03 08:35:08
 * @LastEditTime: 2021-12-23 14:20:05
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\components\title1.vue
-->
<template>
  <p class="big-p">
    <span>{{ titleb }}</span>
    <span>{{ titles }}</span>
  </p>
</template>

<script>
export default {
  name: "",
  props: {
    titleb: {
      type: String,
      default: "",
    },
    titles: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.big-p {
  font-size: 60px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
  span:last-child {
    font-weight: 200;
  }
}
@media screen and (max-width: $mobile-width) {
  .big-p {
    font-size: 0.55rem;
    display: flex;
    flex-direction: column;
  }
}
</style>
