<!--
 * @Author: your name
 * @Date: 2021-12-03 08:00:49
 * @LastEditTime: 2021-12-28 14:53:35
 * @LastEditors: Please set LastEditors
 * @Description:
 * @FilePath: \new-website-1123\src\views\special\fx101\page2.vue
-->
<template>
  <div class="fx101-page2">
    <div class="img-div"></div>
    <div class="text-div">
      <h4>为您量身定制的气象站</h4>
      <h5 class="p-24">
        光伏自动气象测报站可监测空气温湿度、风速、风向、气压、雨量、光照强度、总辐射量等常规气象要素，<br />
        可在无人值守的恶劣环境下全天候全自动运行，将所测数据通过4G/5G网络传输到物联网<br />
        平台存储和分析。系统利用太阳能供电，无需另外接电。
      </h5>

      <ul class="data-x101-ul outer_clear">
        <li>
          <div class="data-x101-number">120000条</div>
          <p>数据储存量</p>
        </li>
        <li>
          <div class="data-x101-number">20种</div>
          <p>定制安装传感器</p>
        </li>
        <li>
          <div class="data-x101-number">4G/5G</div>
          <p>网络传输</p>
        </li>
        <li>
          <div class="data-x101-number">语音</div>
          <p>智能语音播报</p>
        </li>
        <li>
          <div class="data-x101-number">多端查看</div>
          <p>APP、小程序、WEB三端查看</p>
        </li>
      </ul>
    </div>
    <div class="filter-div"></div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.fx101-page2 {
  position: absolute;
  top: 0;
  height: 100vh;
  opacity: 0;
  width: 100%;
  overflow: hidden;
  .img-div {
    position: absolute;
    margin-top: 8%;
    height: 80%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    img {
      height: 100%;
    }
  }
  .filter-div {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 1)
    );
  }
  .text-div {
    opacity: 0;
    position: absolute;
    text-align: center;
    z-index: 2;
    width: 100%;
    top: 15vh;
    left: 0;
    // left: 50%;
    // transform: translateX(-50%) translateY(-50%);
    font-weight: bold;
    color: $color-text;
    line-height: 40px;
    h4 {
      font-size: 60px;
      margin-bottom: 60px;
    }
    h5 {
      width: 1200px;
      margin: 0 auto;
      font-weight: normal;
      font-size: 24px;
    }

    .data-x101-ul {
      width: 960px;
      margin: 0 auto;
      margin-top: 60px;
      li {
        width: 480px;
        float: left;
        padding: 40px;
        font-size: 22px;
        .data-x101-number {
          color: $color-active;
          font-size: 60px;
          margin-bottom: 26px;
        }
        p {
          font-weight: normal;
        }
      }
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .fx101-page2 {
    position: relative;
    top: unset;
    padding-top: 0.91rem;
    height: auto;
    opacity: 1;
    bottom: 0;
    z-index: 2;
    height: 35%;
    .text-div {
      top: 0;
      opacity: 1;
      position: relative;
      h4 {
        font-size: 0.55rem;
        margin-bottom: 0.27rem;
      }
      h5 {
        width: 86%;
        margin: 0 auto;
        font-size: 0.27rem;
      }
      .data-x101-ul {
        margin: auto;
        margin-top: 0.36rem;
        width: 86%;
        text-align: left;
        li {
          width: 40%;
          font-size: 0.27rem;
          padding: 0;
          &:nth-child(2n - 1) {
            width: 60%;
          }
          .data-x101-number {
            line-height: 0.73rem;
            font-size: 0.55rem;
            margin-bottom: 6px;
          }
        }
      }
    }
    .filter-div {
      opacity: 1;
      background-image: linear-gradient(
        to bottom,
        rgba(23, 23, 23, 0),
        rgba(23, 23, 23, 0.7),
        rgba(23, 23, 23, 0.85),
        rgba(23, 23, 23, 0.95)
      );
    }
  }
}
</style>
