<!--
 * @Author: your name
 * @Date: 2021-12-03 08:04:17
 * @LastEditTime: 2021-12-28 14:55:42
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\page3.vue
-->
<template>
  <div class="fx101-page3">
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/x101/2.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.fx101-page3 {
  display: flex;
  justify-content: center;
  position: relative;
  top: 0;
  width: 100%;
  height: 100vh;
  .img-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      height: 80%;
      margin-top: 8%;
      // width: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      object-fit: contain;
      // object-fit: cover;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .fx101-page3 {
    display: none;
  }
}
</style>
