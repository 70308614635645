<!--
 * @Author: your name
 * @Date: 2021-12-03 08:34:35
 * @LastEditTime: 2021-12-16 09:43:58
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\page5.vue
-->
<template>
  <div class="fx101-page5 pt80">
    <div class="text-div">
      <ctitle titleb="传感器集成"></ctitle>
    </div>
    <div class="img-div">
      <img
        class="data-img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/x101/6.png"
        alt="上太科技"
        @load="imgLoad"
      />
      <div class="i-desc demo-one">
        <span class="line-span"></span>
        <p class="line-label">雨量传感器</p>
      </div>

      <div class="i-desc demo-two">
        <span class="line-span"></span>
        <p class="line-label">风向传感器</p>
      </div>

      <div class="i-desc demo-three">
        <span class="line-span"></span>
        <p class="line-label">定制传感器</p>
      </div>

      <div class="i-desc demo-four">
        <span class="line-span"></span>
        <p class="line-label">总辐射</p>
      </div>

      <div class="i-desc demo-five">
        <span class="line-span"></span>
        <p class="line-label">光合有效辐射</p>
      </div>

      <div class="i-desc demo-six">
        <span class="line-span"></span>
        <p class="line-label">风速传感器</p>
      </div>
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1.vue";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    imgLoad() {
      let div = document.querySelector(".fx101-page5");
      let img = div.querySelector(".data-img");
      let one = div.querySelector(".demo-one");
      let two = div.querySelector(".demo-two");
      let three = div.querySelector(".demo-three");
      let four = div.querySelector(".demo-four");
      let five = div.querySelector(".demo-five");
      let six = div.querySelector(".demo-six");
      one.style.left = `calc(50% + ${img.width / 2}px - 50px)`;
      two.style.left = `calc(50% + ${img.width / 2}px - 5px)`;
      three.style.left = `calc(50% + ${img.width / 3}px + 16px)`;
      four.style.right = `calc(50% - 5px)`;
      five.style.right = `calc(50% + ${img.width / 4}px + 13px)`;
      six.style.right = `calc(50% + ${img.width / 2}px - 10px)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.fx101-page5 {
  position: relative;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .img-div {
    height: 78%;
    position: relative;
    margin-top: 60px;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .i-desc {
    position: absolute;
    top: 40%;
    overflow: hidden;
    &.demo-two {
      top: 21%;
    }
    &.demo-three {
      top: 5%;
    }
    &.demo-four {
      top: 1%;
      p {
        padding-left: 0;
        padding-right: 200px;
        text-align: left;
      }
    }
    &.demo-five {
      top: 9%;
      p {
        padding-left: 0;
        padding-right: 200px;
        text-align: left;
      }
    }
    &.demo-six {
      top: 36%;
      p {
        padding-left: 0;
        padding-right: 200px;
        text-align: left;
      }
    }

    .line-span {
      display: block;
      position: absolute;
      top: 0px;
      left: 0;
      width: 0;
      height: 1px;
      background-color: rgba($color: #fff, $alpha: 0.5);
    }
    p {
      margin: 0;
      white-space: nowrap;
      color: $color-active;
      padding-left: 100px;
      position: relative;
      height: 0;
      // height: 16px;
      margin-top: 10px;
      overflow: hidden;
      font-weight: bold;
      font-size: 22px;
    }
  }
}
@media screen and (max-height: 800px) {
  .fx101-page5 {
    .img-div {
      height: 68%;
    }
  }
}
@media screen and (min-height: 800px) {
  .fx101-page5 {
    .img-div {
      height: 78%;
    }
  }
}
</style>
