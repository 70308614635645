<!--
 * @Author: your name
 * @Date: 2021-12-03 08:29:42
 * @LastEditTime: 2021-12-28 15:00:20
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\page4.vue
-->
<template>
  <div class="fx101-page4">
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/x101/2.png"
        alt="上太科技"
      />
    </div>
    <div class="text-div">
      <span>多类型<br />高融合</span>
    </div>
    <div class="filter-div"></div>
  </div>
</template>

<script>
export default {
  name: "fx101-page4",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.fx101-page4 {
  position: absolute;
  top: 0;
  height: 100vh;
  opacity: 0;
  width: 100%;
  overflow: hidden;
  .img-div {
    position: absolute;
    margin-top: 8%;
    height: 80%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    img {
      height: 100%;
    }
  }
  .filter-div {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 1)
    );
  }
  .text-div {
    opacity: 0;
    position: absolute;
    text-align: center;
    z-index: 2;
    width: 100%;
    top: 40%;
    left: 0;
    // left: 50%;
    // transform: translateX(-50%) translateY(-50%);
    display: flex;
    justify-content: center;
    font-size: 100px;
    font-weight: bold;
    color: $color-text;
    line-height: 120px;
  }
}

@media screen and (max-width: $mobile-width) {
  .fx101-page4 {
    position: relative;
    top: 0;
    height: auto;
    opacity: 1;
    padding: 0.81rem 0;
    .img-div {
      position: relative;
      margin: 0;
      height: unset;
      display: flex;
      justify-content: center;
      width: 100%;
      img {
        display: block;
        height: unset;
        width: 86%;
      }
    }

    .filter-div {
      opacity: 1;
      background-image: linear-gradient(
        to bottom,
        rgba(23, 23, 23, 0.9),
        rgba(23, 23, 23, 0.9),
        rgba(23, 23, 23, 0.6),
        rgba(23, 23, 23, 0.9),
        rgba(23, 23, 23, 0.9)
      );
    }

    .text-div {
      opacity: 1;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      font-size: 0.73rem;
      line-height: 0.73rem;
    }
  }
}
</style>
