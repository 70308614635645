<!--
 * @Author: your name
 * @Date: 2021-12-03 07:49:45
 * @LastEditTime: 2022-01-26 15:03:21
 * @LastEditors: Please set LastEditors
 * @Description:
 * @FilePath: \new-website-1123\src\views\special\fx101\page1.vue
-->
<template>
  <div class="fx101-page1">
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/x101/1.png"
        alt="上太科技"
      />

      <div class="i-desc demo-1">
        <span class="line-span"></span>
        <p class="line-label">温度、湿度、风速</p>
      </div>
      <div class="i-desc demo-2">
        <span class="line-span"></span>
        <p class="line-label">风向</p>
      </div>
    </div>
    <div class="text-div">
      <span class="model-span din">ST-X101</span>
      <span class="label-span">光伏自动气象测报站</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.fx101-page1 {
  display: flex;
  justify-content: center;

  width: 100%;

  .img-div {
    width: 100%;
    overflow: hidden;
    img {
      margin-top: 30vh;
      // width: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      object-fit: contain;
      // object-fit: cover;
    }
  }
  .i-desc {
    display: none;
  }
  .text-div {
    position: absolute;
    top: 10vh;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .model-span {
      font-size: 84px;
      font-weight: 500;
      color: #ffffff;
    }
    .label-span {
      font-size: 36px;
      font-weight: bold;
      color: #ffffff;
      // margin-top: 20px;
    }
  }
}
.page2-div {
  display: none;
}
@media screen and (max-width: $mobile-width) {
  .fx101-page1 {
    width: 100%;
    overflow: hidden;
    position: relative;
    .img-div {
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      img {
        width: 86%;
        position: relative;
        left: 0;
        transform: translateX(-0);
        object-fit: contain;
      }
    }
    .text-div {
      .model-span {
        font-size: 0.55rem;
      }
      .label-span {
        font-size: 0.33rem;
        margin-top: 20px;
      }
    }

    .i-desc {
      position: absolute;
      display: block;
      width: 30%;
      top: 54.7%;
      &.demo-1 {
        right: 60%;
        .line-label {
          padding-right: 20px;
        }
      }
      &.demo-2 {
        left: 60%;
        text-align: right;
      }
      .line-span {
        display: block;
        height: 1px;
        width: 100%;
        background-color: $color-active;
      }
      .line-label {
        color: #fff;
        font-size: 0.22rem;
        margin-top: 6px;
      }
    }
  }

  .page2-div {
    display: block;
    position: absolute;
    bottom: 0;
  }
}
</style>
