<!--
 * @Author: your name
 * @Date: 2021-12-03 08:48:05
 * @LastEditTime: 2021-12-28 15:28:26
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\page7.vue
-->
<template>
  <div class="fx101-page7">
    <div class="text-div">
      <ctitle titleb="气象预警"></ctitle>

      <p class="desc-p p-24">
        用以农业气象自然环境监测系统软件，精确测量风力风频，光照强度，<br />
        温度湿度等环境监控系统，确保灾害性天气预警、天气预报服务信息<br />
        从而准确及时地传达。
      </p>
    </div>

    <img
      class="data-img"
      src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/x101/7.jpg"
      alt="上太科技"
    />
    <img
      class="data-mobile-img"
      src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/x101/7.jpg"
      alt="上太科技"
    />
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {
      isWidth: false,
      oX: 0,
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.fx101-page7 {
  position: relative;
  height: 100vh;
  position: relative;
  text-align: center;
  justify-content: center;
  .text-div {
    position: absolute;
    z-index: 2;
    top: 10vh;
    left: 10vw;
    text-align: left;
  }
  .data-img {
    display: unset;
  }
  .data-mobile-img {
    display: none;
  }
}
@media screen and (max-width: $mobile-width) {
  .fx101-page7 {
    height: auto;
    width: 100%;
    overflow: hidden;
    .text-div {
      position: relative;
      z-index: 2;
      width: 86%;
      top: 0;
      left: 7%;
      text-align: center;
      .p-24 {
        margin-top: 10px;
      }
    }

    .data-img {
      display: none;
    }
    .data-mobile-img {
      display: block;
      width: 86%;
      margin: 20px auto;
    }
  }
}
</style>
