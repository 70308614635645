<!--
 * @Author: your name
 * @Date: 2021-12-28 15:01:02
 * @LastEditTime: 2021-12-28 15:14:13
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\x101\components\page5Mobile.vue
-->
<template>
  <div class="fx101-page5-mobile pt80">
    <div class="text-div">
      <ctitle titleb="传感器集成"></ctitle>
    </div>
    <div class="img-div img-right">
      <img
        class="data-img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/x101/6.png"
        alt="上太科技"
        @load="imgLoad"
      />
      <div class="i-desc demo-four">
        <span class="line-span"></span>
        <p class="line-label">总辐射</p>
      </div>

      <div class="i-desc demo-five">
        <span class="line-span"></span>
        <p class="line-label">光合有效辐射</p>
      </div>

      <div class="i-desc demo-six">
        <span class="line-span"></span>
        <p class="line-label">风速传感器</p>
      </div>
    </div>
    <div class="img-div img-left">
      <img
        class="data-img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/x101/6.png"
        alt="上太科技"
      />
      <div class="i-desc demo-one">
        <span class="line-span"></span>
        <p class="line-label">雨量传感器</p>
      </div>

      <div class="i-desc demo-two">
        <span class="line-span"></span>
        <p class="line-label">风向传感器</p>
      </div>

      <div class="i-desc demo-three">
        <span class="line-span"></span>
        <p class="line-label">定制传感器</p>
      </div>
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1.vue";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    imgLoad() {
      // let div = document.querySelector(".fx101-page5-mobile");
      // let img = div.querySelector(".data-img");
      // let one = div.querySelector(".demo-one");
      // let two = div.querySelector(".demo-two");
      // let three = div.querySelector(".demo-three");
      // let four = div.querySelector(".demo-four");
      // let five = div.querySelector(".demo-five");
      // let six = div.querySelector(".demo-six");
      // one.style.left = `calc(50% + ${img.width / 2}px)`;
      // two.style.left = `calc(50% + ${img.width / 2}px )`;
      // three.style.left = `calc(50% + ${img.width / 3}px)`;
      // four.style.right = `calc(50% - 5px)`;
      // five.style.right = `calc(50% + ${img.width / 4}px)`;
      // six.style.right = `calc(50% + ${img.width / 2}px)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.fx101-page5-mobile {
  position: relative;
  width: 100%;
  overflow: hidden;
  .text-div {
    text-align: center;
  }
  .img-div {
    width: 86%;
    margin: auto;
    position: relative;
    margin-top: 0.55rem;
    &.img-right {
      transform: translateX(40%);
    }
    &.img-left {
      transform: translateX(-50%);
    }
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .i-desc {
    position: absolute;
    top: 40%;
    overflow: hidden;
    width: 2.6rem;
    &.demo-one {
      left: 90%;
      p {
        text-align: right;
      }
    }
    &.demo-two {
      top: 21%;
      left: 98%;
      p {
        text-align: right;
      }
    }
    &.demo-three {
      top: 5%;
      left: 85%;
      p {
        text-align: right;
      }
    }
    &.demo-four {
      top: 1%;
      right: 50%;
    }
    &.demo-five {
      top: 9%;
      right: 78%;
      p {
        padding-left: 0;
        text-align: left;
      }
    }
    &.demo-six {
      top: 35%;
      right: 90%;
      p {
        padding-left: 0;
        text-align: left;
      }
    }

    .line-span {
      display: block;
      position: absolute;
      top: 0px;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: rgba($color: #fff, $alpha: 0.5);
    }
    p {
      margin: 0;
      white-space: nowrap;
      color: $color-active;
      position: relative;
      height: auto;
      // height: 16px;
      margin-top: 6px;
      overflow: hidden;
      font-weight: bold;
      font-size: 0.27rem;
    }
  }
}
</style>
